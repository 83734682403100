.footer{
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    height: 170px;
    position: relative;
    left: 0;
    bottom:0;
}

.Email {
    display: flex;
    position: absolute;
    isolation: isolate;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    width: 69px;
    height: 69px;
    right: 10%;
    bottom: 25%;
    overflow: hidden;
  }

  .Vector {
    width: 55px;
    position: absolute;
    left: 0px;
    top: 9px;
    &:hover{
      cursor: pointer;
    }
  }
  
  #AboutPrivacyFaq {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0px;
    text-decoration: none;
    line-height: 200%;
    text-transform: none;
    position: absolute;
    left: 10%;
    bottom: 15%;
  }

  #PrivacyFaq {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0px;
    text-decoration: none;
    line-height: 300%;
    text-transform: none;
    position: absolute;
    left: 6%;
    bottom: 10%;
  }

  a {
    color: white;
    text-decoration: none;
  }

.footer.about {
    background-color: #0C0A0A; /* Adjust the opacity as needed */
    height: 140px;
}
.Email.about{
  right: 7%;
  bottom:auto;
  height: auto;
  min-height: 1px;
  top:10%;
  width: 9%;
  aspect-ratio: 173/75;
  align-items: center;
  justify-content: flex-start;
  color: white;
  cursor: pointer;
  gap: 10px;
  img{
    position: relative;
    top:auto;
    left:auto;
    width: 25px;
  }
  p{
    font-family: Sansation;
    font-weight: 400;
    font-size: 18px;
  }
}

.Coffee.about{
  position: absolute;
  display: flex;
  right: 4%;
  height: auto;
  min-height: 1px;
  bottom:10%;
  width: 12%;
  aspect-ratio: 194/75;
  align-items: center;
  justify-content: flex-start;
  color: white;
  cursor: pointer;
  gap: 10px;
  svg{
    width: 25px;
    aspect-ratio: 1/1;
    cursor: pointer;
  }
  p{
    font-family: Sansation;
    font-weight: 400;
    font-size: 18px;
    margin-right: 3%;
  }
}

.Vector.about {
  width: 35px;
  left:30% ;
  top:30%;
}

@media (max-width: 675px) {
  .footer{
    height:7rem;
    
  }
  .Email {
    width: 10%;
    height: auto;
    aspect-ratio: 55/41;
    right: 10%;
    top: 40%;
    overflow: hidden;

    .Vector {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  #AboutPrivacyFaq {
    font-size: 3.5vw;
  }

  #PrivacyFaq {
    font-size: 3.5vw;
    left: 10%;
    top: 15%;
  }

  .footer.about {
    background-color:black; /* Adjust the opacity as needed */
    height: 7rem;
  }
  .Vector.about {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .Email.about{
    top: 20%;
    width:25px;
    height: 20px;
    right: 9.6%;

  }
  .Coffee.about{
    bottom: 20%;
  }
  #joinContentApp{
    display: none;
  }
}