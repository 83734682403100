#generator{
    background: rgb(16, 0, 35);
    mix-blend-mode: hard-light;
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: column;

    min-height: 55vw;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    background-attachment: fixed;
    

    #circle1{
        position:absolute;
        background-color: rgba(115, 22, 149, 0.3);
        filter: blur(5rem);
        width: 54%;
        aspect-ratio: 1030/896;
        top:-27%;
        left:-15%;
        border-radius: 100%;
        z-index:1;
    }
    #circle2{
        position:absolute;
        background-color: rgba(15, 111, 6, 0.3);
        filter: blur(5rem);
        width: 67%;
        aspect-ratio: 1283/1003;
        top:-18%;
        left:67%;
        border-radius: 100%;
        z-index:1;
    }
    #circle3{
        position:absolute;
        background-color: rgba(18, 7, 136, 0.3);
        filter: blur(5rem);
        width: 67%;
        aspect-ratio: 1283/1003;
        top:55%;
        left:12%;
        border-radius: 100%;
        z-index:1;
    }
    .body{
        text-align: center;
        width:100%;
        padding-top: 2%;
        color:white;

        .playlistContainer{
            color:white;
            position: relative;
            padding:0 3% 0 3%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .searching{
                color:white;
                margin-bottom:2%;

                flex-direction: row;
                justify-content: space-between;
                display: flex;
                form {
                    display: flex;
                    justify-content: space-between;
                    flex-basis:64%;
                    aspect-ratio: 1150/70;
                    border-radius: 40px;
                    background-color: rgba(100, 65, 144, 0.28);
                    align-items: center;
                    svg{
                        padding:  0% 2% 0% 2%;
                        cursor: pointer;
                        z-index: 2;
                    }
                    .inactive-svg{
                        cursor:auto;
                    }

                    input {
                        background-color: transparent;
                        border-color: transparent;
                        display: flex;
                        z-index:2;
                        border:none;
                        padding: 0;
                        color: white;
                        width:85%;
                        height: 100%;
                        font-size: 1.3vw;
                        font-family: Lexend Deca;

                        &:focus {
                            outline: none;
                          }
                        &::placeholder{
                            color:rgba(255, 255, 255, 0.25);
                            font-family: Lexend Deca;
                        }
                    }

                }
            }
            .searchSong{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;
                align-items: stretch;
                max-height: 420px;
                .songList{
                    position: relative;
                    background-color: rgba(217, 217, 217, 0.183);
                    border-radius: 45px;
                    padding: 3%;
                    padding-right: 1%;
                    z-index:2;
                    .scrollList{
                        width:97%;
                        height: 100%;
                        margin-right:1%;
                        padding-right: 2%;
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                        z-index:2;

                        gap:5%;

                        &::-webkit-scrollbar {
                            width: 10px;
                            margin-right: 5%;
                        }
                        
                        /* Styling the scrollbar thumb (the draggable part) */
                        &::-webkit-scrollbar-thumb {
                            background-color: white;
                            border-radius: 5px;
                        }
                        
                        &::-webkit-scrollbar-thumb:hover {
                            background-color: white;
                        }
                        

                        &::-webkit-scrollbar-thumb:active {
                            background-color: white;
                        }
                        

                        &::-webkit-scrollbar-track {
                            background-color: transparent;

                        }
                        
                        .list-card{
                            width:92%;
                            padding: 0% 4% 0 4%;
                            background-color: rgba(0, 0, 0, 0.62);
                            border-radius: 20px;
                            aspect-ratio: 1027/100;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            .list-card_img{
                                width: 5%;
                                aspect-ratio: 1/1;
                                img{
                                    width:100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .list-card_content{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                width: 80%;
                                margin-right:5%;
                                h4{
                                    font-size: 0.9vw;
                                    width:33%;
                                }
                            }
                            .list-card_plus{
                                width: 4%;
                                aspect-ratio: 1/1;
                                cursor: pointer;
                            }
                            .inactive-svg{
                                cursor: auto;
                            }
                        }

                        .playlist-card{
                            width:92%;
                            padding: 0% 3% 0 3%;
                            background-color: rgba(0, 0, 0, 0.62);
                            border-radius: 20px;
                            aspect-ratio: 540/85;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            .playlist-card_img{
                                width: 10%;
                                aspect-ratio: 1/1;
                                min-height: 2px;
                                margin-right:5%;
                                img{
                                    width:100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .playlist-card_content{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                width: 85%;
                                margin-right:2%;

                                h4{
                                    font-size: 0.7vw;
                                    width:50%;
                                }
                            }
                            .playlist-card_remove{
                                width: 8%;
                                position: relative;
                                min-height: 2px;
                                aspect-ratio: 1/1;
                                cursor: pointer;
                            }
                            .inactive-svg{
                                cursor: auto;
                            }
                        }
                    }
                }

            }

            .done{
                margin:3%;
                z-index: 2;
                button{
                    z-index: 2;
                    width:16%;
                    aspect-ratio: 297/60;
                    border:none;
                    font-family: Sansation;
                    border-radius: 30px;
                    background-color: rgba(65, 18, 77, 0.79);
                    color: white;
                    font-size: 1.3vw;
                    cursor: pointer;
                }
                .unactive_button{
                    cursor: auto;
                    background-color: rgba(65, 18, 77, 0.5);
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .flex-two{
        flex-basis:60%;
        aspect-ratio: 1200/550;
    }
    .flex-one{
        flex-basis:29%;
    }

    .iconInfo{
        cursor: pointer;
        z-index: 20;
        width: 2%;
        min-height: 2px;
        aspect-ratio: 1/1;
    }
    .tooltiptext {
        visibility: hidden;
        min-width: 120px;
        width: 24%;
        background-color: rgba(217, 217, 217, 0.1);

        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.6%;
        position: absolute;
        z-index: 20;
        margin-left: -20.8%;
        margin-top: -3.2%;
        font-size: 0.8vw;
        font-family: Lexend;
        font-weight: 200;
    }

    .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 85%;
        margin-left: -5px;
        border-width: 0.7vw;
        border-style: solid;
        border-color: rgba(217, 217, 217, 0.1) transparent transparent transparent;
    }

    .iconInfo:hover .tooltiptext {
        visibility: visible;
    }

    .pussyCat1{
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Lexend Deca;
        color:rgba(255, 255, 255, 0.15);
        img{
            width: 34%;
        }
    }
    .pussyCat2{
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;        
        font-family: Lexend Deca;
        color:rgba(255, 255, 255, 0.15);
        img{
            width: 49%;
        }
    }

}
.hiddenMobile{
    display: none;

}

@media (max-width: 700px) {
    .hiddenMobile{
        display: block;
        
    }
    #circle1, #circle2,#circle3{
        display:none;
    }
    #generator{
        min-height: 600px;
        .pussyCat1 img {
            width: 70%;
        }
        .body{
            .playlistContainer{
                background-color: rgba(217, 217, 217, 0.3);
                margin: 0 5% 5% 5%;
                border-radius: 30px;
                padding: 5% 0%;
                .searching{
                    margin: 0 5% 10% 5%;

                    form{
                        flex-basis: 100%;
                        aspect-ratio: 320/28;
                        background-color: rgba(0, 0, 0, 0.22);
                        input{
                            font-size: 3vw;
                        }
                    }
                }
                .searchSong{
                    flex-direction: column;
                    max-height: none;
                    .songList.flex-two,.songList.flex-one{
                        aspect-ratio: auto;
                        flex-basis: 100%;
                        width: 100%;
                        background-color: transparent;
                        padding: 1% 0 0 0;
                        // max-height: 50vh;
                        .scrollList{
                            width: calc(96% - 3px);
                            padding: 0;
                            padding-left: calc(2% + 3px);
                            gap: 5%;
                            margin: 0;
                            justify-content: flex-start;
                            max-height: 45vh;
                            .list-card, .playlist-card{
                                min-height: 5vh;
                                aspect-ratio: auto;
                                border-radius: 15px;
                                margin: 0 2% 5% 2%;
                                width: 88%;
                                .list-card_img, .playlist-card_img{
                                    width: 7%;
                                    margin-right: 2%;
                                }
                                .list-card_content h4, .playlist-card_content h4 {
                                        font-size: 2vw;
                                }
                                .list-card_plus, .playlist-card_remove{
                                    min-height: 1px;
                                    width: 7%;

                                }
                            }
                            .playlist-card{
                                margin-bottom: 5%;
                                min-height: 5vh;
                            }
                        }
                        .scrollList::-webkit-scrollbar {
                            width: 3px; 
                            margin-right: 5%; 
                        }
                    }
                    .songList.flex-one{
                        background-color: rgba(28, 22, 25, 0.37);
                        border-radius: 0;
                        .scrollList{
                            max-height: 25vh;
                        }
                        .scrollList::-webkit-scrollbar{
                            width: 0;
                            margin-right: 0%; 
                        }
                    }


                }
                .done{
                    margin: 0;
                    min-height: 5vh;
                    background-color: rgba(28, 22, 25, 0.37);
                    margin-bottom: -6%;
                    border-radius: 0 0 30px 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    button{
                        width: 30%;
                        aspect-ratio: 108/34;
                        font-size: 3vw;
                    }
                }
            }
        }
        .mobileGenTitle{
            position: relative;
        }
        .iconInfo{
            position: absolute;
            right: 2vh;
            top:0;
            width: 5%;
            .tooltiptext{
                min-width: 25vh;
                font-size: 2vw;
                margin-top: -5vh;
                bottom: 120%;
                left: 0%;
                margin-left: -22vh;
                background-color: rgba(120, 120, 120, 0.7);
                &::after{
                    border-color: rgba(120, 120, 120, 0.7) transparent transparent transparent;
                    border-width: 1vw;
                    margin-left: 1.5vh;
                }
            }
        }

    }

}
