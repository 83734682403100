.generatorContainer{
    position: relative;
    display: flex;
    padding: 5%;
    padding-top: 2%;
    width: 90%;
    gap: 3%;
    align-items: stretch;
    max-height: 560px;
    justify-content: space-between;
    .playlistData{
        width: 35%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        z-index: 2;
        .data_img{
            width: 40%;
            height: 40%;
            min-height: 2px;
            aspect-ratio: 1/1;
            background-image: linear-gradient(to bottom right, rgba(47, 25, 136, 0.63), rgba(174, 0, 167, 0.17));
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
                width:100%;
                height:100%;
                object-fit: cover;
                border-radius: 15px;
            }
        }
        .data_name{
            position: relative;
            width: 100%;
            form{
                display: flex;
                justify-content: space-between;
                width: 100%;
                aspect-ratio: 658/84;
                border-radius: 20px;
                background-color: rgba(217, 217, 217, 0.12);
                align-items: center;
                input {
                    background-color: transparent;
                    border-color: transparent;
                    display: flex;
                    z-index:2;
                    border:none;
                    padding: 0;
                    color: white;
                    margin-left:4%;
                    margin-right:4%;
                    width:100%;
                    height: 100%;
                    font-size: 1vw;
                    font-family: Sansation;

                    &:focus {
                        outline: none;
                      }
                    &::placeholder{
                        color:rgba(255, 255, 255, 0.17)
                    }
                }
            }
        }
        .data_description{
            position: relative;
            width: 100%;
            form{
                display: flex;
                justify-content: flex-start;
                width: 100%;
                aspect-ratio: 658/191;
                border-radius: 20px;
                background-color: rgba(217, 217, 217, 0.12);
                align-items: flex-start;
                textarea {
                    background-color: transparent;
                    border-color: transparent;
                    display: flex;
                    z-index:2;
                    border:none;
                    padding: 0;
                    color: white;
                    margin-left:4%;
                    margin-right:4%;
                    width:98%;
                    padding-top: 2%;
                    padding-bottom: 2%;
                    resize: none;

                    height: 100%;
                    font-size: 1vw;
                    font-family: Sansation;
                    word-wrap: break-word;

                    &:focus {
                        outline: none;
                    }
                    &::placeholder{
                        color:rgba(255, 255, 255, 0.17)
                    }
                }
            }
        }
    }
    .generatedTracks{
        position: relative;
        width: 55%;
        background-color: rgba(217, 217, 217, 0.183);
        border-radius: 20px;
        padding: 1% 1% 1% 3%;
        z-index:2;
        aspect-ratio: 961/569;
        .scrollList{
            width:97%;
            height: 100%;
            margin-right:1%;
            padding-right: 2%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            z-index:2;
            gap:5%;

            &::-webkit-scrollbar {
                width: 10px;
                margin-right: 5%;
            }
            
            /* Styling the scrollbar thumb (the draggable part) */
            &::-webkit-scrollbar-thumb {
                background-color: white;
                border-radius: 5px;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background-color: white;
            }
            

            &::-webkit-scrollbar-thumb:active {
                background-color: white;
            }
            

            &::-webkit-scrollbar-track {
                background-color: transparent;

            }

            .gPlaylist-card{
                position: relative;
                display: flex;
                flex-direction: row;
                width: 99%;
                aspect-ratio: 1000/85;
                justify-content: space-between;
                align-items: center;
                margin-right: 1%;
                .gPlaylist-card_img{
                    min-height: 2px;
                    width: 7%;
                    img{
                        width: 100%;
                        object-fit: cover;
                        min-height: 2px;
                    }
                }
                .gPlaylist-card_content{
                    display: flex;
                    flex-direction: row;
                    width: 70%;
                    margin-right: 10%;
                    text-align: left;
                    justify-content: flex-start;
                    gap: 10%;
                    h4{
                        width: 50%;
                    }
                }
                .gPlaylist-card_remove{
                    min-height: 2px;
                    width: 4%;
                    svg{
                        width: 100%;
                        cursor:pointer;
                    }
                }
            }
        }

    }


}

.buttons{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    width: 100%;
    .buttonCreate{
        margin:2%;
        z-index: 2;
        width: 10%;
        button{
            z-index: 2;
            width:100%;
            aspect-ratio: 185/53;
            border:none;
            font-family: Lexend;
            border-radius: 20px;
            background-color: rgba(65, 18, 77, 0.79);
            color: white;
            font-size: 1.5vw;
            cursor: pointer;
            margin: 0;
            
        }
        .unactive_button{
            cursor: auto;
            background-color: rgba(65, 18, 77, 0.5);
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

/* Add this modal CSS to your existing styles or in a separate stylesheet */
.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: Sansation;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 52%;
    aspect-ratio: 998/168;
    transform: translate(-50%, -50%);
    color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .center{
        text-align: center;
    }
  }
  
  .close-btn {
    position: absolute;
    top: 10%;
    right: 3%;
    width: 1.7%;
    aspect-ratio: 1/1;
    cursor: pointer;
  }

  button {
    margin: 10px;
    z-index: 2;
    width: 20%;
    aspect-ratio: 185/53;
    border:none;
    font-family: Lexend;
    background-color: rgba(65, 18, 77, 0.79);
    color: white;
    font-size: 1vw;
    cursor: pointer;
  }


  .modal-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin-bottom: 1%;
    button {
        margin: 0;
        width: 15%;
        aspect-ratio: 14/4;
        border-radius: 25px;
        background-color: rgba(217, 217, 217, 0.15);
        font-size: 0.8vw;
        font-family: Urbanist;
        font-weight: 800;
    }
    .mainButton{
        background-color: rgba(146, 18, 141, 0.58);
      }
  }

  #modal-text{
    margin-top: 5%;
  }

@media (max-width: 700px) {
    .generatorContainer{
        flex-direction: column;
        align-items: center;
        .playlistData{
            width: 50%;
            .data_img{
                width: 100%;
                margin-bottom: 5%;
            }
            .data_name form, .data_description form{
                text-align: center;
                justify-content: center;
                background-color: transparent;
                margin-bottom: 5%;
                input, textarea{
                    text-align: center;
                    font-size: 3.5vw;
                    &::placeholder{
                        color:white;
                    }
                }
            }
        }
        .boxPlaylist{
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
        }
        .generatedTracks{
            width:96%;
            padding: 5% 2%;
            background-color: rgba(217, 217, 217, 0.2);
            // background-color: transparent;
            text-align: left;
            border-radius: 25px;
            max-height: 20vh;

            .scrollList{
                max-height: 25vh;
                gap: 10%;
                .gPlaylist-card{
                    background-color: rgba(0, 0, 0, 1);
                    border-radius: 15px;
                    min-height: 5vh;
                    width: 93%;
                    padding: 0% 3% 0 3%;
                    .gPlaylist-card_content{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        text-align: center;
                        width: 80%;
                        margin-right:5%;
                        h4{
                            font-size: 1vw;
                            width:30%;
                        }
                    }
                    .gPlaylist-card_img{
                        width: 7%;
                        margin-right: 2%;
                    }
                    .gPlaylist-card_content{ 
                        align-items: center;
                        justify-content: space-between;
                        width: 85%;
                        margin-right:2%;
                        h4{
                            font-size: 2.7vw;
                            width: 50%;
                        }
                    }
                    .gPlaylist-card_remove{
                        min-height: 1px;
                        width: 7%;
                    }

                }

            }
            .scrollList::-webkit-scrollbar {
                width: 3px; 
            }
        }
    }
    .buttons {
        gap:0%;
        justify-content: space-around;
        width: 60%;
        margin: 0 20% 5% 20%;
        .buttonCreate {
            width: 40%;
            margin: 0;
            position: relative;
            button{
                margin: 0;
                font-size: 3vw;
            }
        }
    }
    .modal-content{
        width: 80%;
        padding: 2%;
        .close-btn{
            top: 6%;
            right: 4%;
            width: 3%;
        }
        .modal-buttons{
            button{
                width: 25%;
                font-size: 2.7vw;
                aspect-ratio: 65/24;
            }
        }

    }
}
  
  