html, body{
    height:95%;
}
#root{

    height:100%;
}
#main{
    background: linear-gradient(124.1deg, rgba(27, 10, 48, 1) 23.751012570606566%, rgba(6, 46, 35, 1) 77.91781456990824%);
    mix-blend-mode: hard-light;
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    width: 100%;
    min-height: 800px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
}

  .VectorSmartObject1 {
    width: 170%;
    top: -40%;
    left: -50%;
    position: absolute;
    opacity: 0.3;
    transform: scale(1);
    transform-origin: bottom left;
    animation: changeSize 20s linear infinite;
    img {
        width: 100%;
    }
  }

.container{
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}


#Tastyfy-title{
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-weight: bold;
    font-family: Sansation;
    font-size: 100px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    text-shadow: 0px 8px 4px rgba(63, 61, 89, 1);
    position: relative;
    padding-left: 4rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
    animation: transparencyAnimation 10s infinite;

    @keyframes transparencyAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }

}
  
  
.login-body{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 5rem;
    padding-right:3rem;
    padding-left:4%;

}

#login-button{
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    width: 13rem;
    height: 4.5rem;

}

#button-click{
    background-color: rgba(18, 15, 26, 1);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 1rem 0 1rem;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

#LogIn{
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Urbanist;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    position: relative;
}

#SpotifyImg {
    height: 60%;
    // width: 50%;
    position: relative;

    // top: 20%;
  }


#Tastyfy-info-title{
    white-space: pre-wrap;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    font-weight: 400;
    // font-size: 40px;
    font-size: 3.5vw;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
}

#Tastyfy-info-info {
    white-space: pre-wrap;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    // font-weight: 400;
    font-size: 3vw;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
  }
  
  #Tastyfy-info {
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    width: 50%;
    position: relative;
    
  }
  
  #Email {
    display: flex;
    position: absolute;
    isolation: isolate;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    width: 69px;
    height: 69px;
    right: 10%;
    bottom: 5%;
    overflow: hidden;
  }
  @media (max-width: 1200px) {
    #VectorSmartObject1 {
        height: 170%;
        width:auto;
        top: -20%;
        // left: -15%;
        position: absolute;
        opacity: 0.3;
        animation: changeSize 20s infinite;
      }
  }


@keyframes changeSize {
    0% {
        transform: scale(1);
        transform-origin: bottom left;
    }
    40% {
        transform: scale(0.91);
        transform-origin: bottom left;
    }
    60% {
        transform: scale(1.23);
        transform-origin: top right;
    }
    100% {
        transform: scale(1);
        transform-origin: top right;
    }
    
  }


  @media (max-width: 675px) {
    #main{
        min-height: 600px;
        height: 100%;
        background: linear-gradient(159deg, rgb(48, 7, 62, 1) 25%, rgb(1, 111, 32) 90%);
    }
    #Tastyfy-title{
        font-size: 10vw;
        padding-left: 1rem;
        padding-top: 2rem;
        margin-bottom: 1rem;
    }

    .login-body{
        flex-direction: column;
        gap: 7rem;
        padding: 0%;
    }

    #Tastyfy-info {
        text-align:center;
    }
    #Tastyfy-info-title{
        font-size: 5.5vw;
    }
    #Tastyfy-info-info{
        font-size: 5vw;
    }
    #login-button{
        width: 45%;
        height: auto;
        aspect-ratio: 178/57;

    }
    #button-click{
        width: 65%;
        padding: 0 15% 0 20%;
        aspect-ratio: 178/57;
        justify-content: space-between;
    }
    #LogIn{
        font-size: 6vw;
    }
    #SpotifyImg {
        height: 70%;
    }
    .VectorSmartObject1 {
        width: 534%;
        aspect-ratio: 1621/1000;
        top:-120%;
        left: -180%;
        position: absolute;
        animation: none;
        opacity: 0.35;
        
        img{
            transform: rotate(99deg) scale(-1, 1);
            animation: changeSizeMobile 15s infinite;
        }
      }

      @keyframes changeSizeMobile  {
        0% {
            transform: rotate(99deg) scale(-1, 1);
        }
        40% {
            transform: rotate(99deg) scale(-0.93, 0.93);
        }
        60% {
            transform: rotate(99deg) scale(-1.23, 1.23);
        }
        100% {
            transform: rotate(99deg) scale(-1, 1) scale(1);
        }
        
      }
  }

