#mainA {
    background: linear-gradient(180deg, rgb(0, 0, 0) -1.061617131462919615%,rgba(28, 4, 43, 1) 99.99999999999997%);
    mix-blend-mode: hard-light;
    flex-direction: row;
    width: 100%;
    min-height: 800px;
    padding: 0px;
}

.containerA{
    position: relative;
    min-height: 800px;
    width:100%;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
}

#leftRectangleA {
    background-color: rgba(0, 0, 0, 1);
    width: 14rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}
  
#tastyfyA {
    text-align: left;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Sansation;
    font-weight: 700;
    font-size: 45px;
    letter-spacing: 0px;
    text-shadow: 0px 8px 4px rgba(63, 61, 89, 1);
    position: relative;
}

#backRectangleA {
    background-color: rgba(217, 217, 217, 1);
    border-radius: 30px;
    width: 7rem;
    aspect-ratio:7/3;
    position: relative;
    margin-top: 3rem;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    &:hover {
        background-color: rgba(200, 200, 200, 1);
        cursor:pointer;
    }
    &:active {
        background-color: rgba(180, 180, 180, 1);
    }
}
#vector1A {
    height: 100%;
    position: relative;
    width: 0.5rem;
    height: 1.2rem;
}

  
#backA {
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-family: Urbanist;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    line-height: 270%;
    position:relative;
}
.about-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width:calc(100% - 14rem);
  }
  
  .team {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 12rem;
    margin-top: 2rem;
  }
  
  .team-card img {
    width: 100%;
    aspect-ratio: 1/2;
    object-fit: cover;
    position: relative;
    filter: brightness(0.8);
  }


  .team-card-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    h3{
        white-space: pre-wrap;
        color: rgba(255, 255, 255, 1);
        font-style: normal;
        font-family: Urbanist;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0px;
        text-decoration: none;
        text-transform: none;
        text-align: center;
    }
    .team-card-rectangle{
        background-color: rgba(255, 255, 255, 1);
        width: 90%;
        height: 1px;
        position: relative;
    }
  }

#descriptionA{
    position: relative;
    padding: 5%;
    padding-top: 2rem;
    text-align: center;
    min-height: 12rem;

    h2{
        color: rgba(255, 255, 255, 1);
        font-style: normal;
        font-family: Lexend;
        font-weight: 500;
        font-size: 24px;
    }
}
  
  
#textAbout {
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-family: Urbanist;
    font-weight: 400;
    font-size: 1.6vw;
    letter-spacing: 0px;
    position: relative;
    margin-top: 15px;
    padding: 0 7%;
}
#descriptionA-1{
    display: none;
}
.hiddenArrow{
    display: none;
}

@media (max-width: 675px) {
    #mainA {
        min-height: 600px;
    }

    .containerA{
        position: relative;
        flex-direction:column;       
        min-height: 600px;

    }
    #leftRectangleA {
        background-color:transparent;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
    }
    #tastyfyA {
        font-size: 9vw;
        position: relative;
    }
    #backRectangleA{
        display: none;
    }
    #leftRectangleA {
        width: 10rem;
        margin-left:0;
        

    }
    .about-body {
        // width: 70%;
        margin-right:0px;
        width:80%;
        margin: 0 10% 0 10%;
    }

    #textAbout {
        font-size: 2vw;
    }

    #descriptionA-1{
        display: inline;
        position: relative;
        text-align: center;
    
        h2{
            color: rgba(255, 255, 255, 1);
            font-style: normal;
            font-family: Lexend;
            font-weight: 300;
            font-size: 5vw;
        }
    }
    #descriptionA h2,#descriptionA h4{
        display: none;
    }
    .hiddenArrow{
        display:flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        svg{
            margin-top: -150%;
            cursor: pointer;
        }

    }
    #descriptionA{
        position: relative;
        padding: 5%;
        padding-top: 1rem;
        text-align: center;
        min-height: 1rem;
        font-size: 5vw;
    }
    #textAbout {
        text-align: center;
        font-size: 3.5vw;
        margin: 0 5% 0 5%;
        margin-top: 0;
        padding: 0;
    }

    .team-card {
        width: 60%;
        margin: 0;
        img{
            width: 80%;
            border-radius: 20px;
            aspect-ratio: 162/276;
        }
      }
    .team-card-info{
        width: 85%;
        margin-top:0.7rem;
    }
    .team-card-info h3 {
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
        font-size: 4vw;
    }
    .hidden{
        display: none;
      }
}
  
