.header {
    width: 92%;
    height: 10rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4%;
    padding-right: 4%;
    justify-content: space-between;

    .headTastyfy {
      text-align: left;
      white-space: pre-wrap;
      font-synthesis: none;
      color: rgba(255, 255, 255, 1);
      font-style: normal;
      font-family: Sansation;
      font-weight: 700;
      font-size: 3vw;
      letter-spacing: 0px;
      text-shadow: 0px 4px 4px rgba(63, 61, 89, 1);
      z-index:2;
      cursor:pointer;
    }

    .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      justify-content: space-between;
      margin-left: -6rem;
      // margin: 0 2rem 0 2rem;
      gap: 20%;
      z-index:2;

      .menuItem {
        text-align: left;
        font-synthesis: none;
        color: rgba(255, 255, 255, 1);
        font-style: normal;
        font-family: Sansation;
        position: relative;
        white-space: nowrap;
        font-weight: 700;
        font-size: 2.1vw;
        min-width: 11vw;
        letter-spacing: 0px;
        cursor: pointer;
        z-index:2;
        &:hover{
          color:rgba(255, 255, 255, 0.5);
        }
      }
    }

    #avatar {
      height: 55px;
      width: 55px;
      border-radius: 100%;
      border: 2px solid white;
      margin: 0px;
      object-fit: cover;
      z-index:2;
      &:hover{
        cursor: pointer;
        height: 50px;
        width: 50px;
        margin: 2.5px;
      }
    }
  }

  @media (max-width: 675px) {
    .header {
      background-color: white;
      height: 8rem;
      margin-bottom:2rem;
      .headTastyfy {
        font-size: 8vw;
      }
      .menu {
        position:absolute;
        margin-left:0;
        width:66%;
        aspect-ratio: 260/30;
        padding:1%;
        left:15%;
        border-radius: 60px;
        align-items: center;
        margin-bottom:-8rem;
        background-color: rgba(217, 217, 217, 0.2);
        gap:0;
        

        .menuItem {
          font-size:4.5vw;
          width: 50%;
          text-align: center;
          
        }
        .menuItem.PG{
          background-color: rgba(217, 217, 217, 1);
          color:rgba(44, 39, 39, 1);
          height:100%;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      #avatar {
        width: 10%;
        height: auto;
        aspect-ratio: 1/1;
      }
    }

  }