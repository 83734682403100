#analytics {
    background: linear-gradient(354.67deg, rgba(9, 2, 14, 1) 9.08%, rgba(39, 11, 56, 1) 85.83%);
    mix-blend-mode: hard-light;
    display: flex;
    position: relative;
    isolation: isolate;
    flex-direction: column;

    min-height: 1800px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    background-attachment: fixed;
  

  
    #analyze {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      margin-top: 5%;
      .icon1{
        top:20%;
        left:81%;
      }
      .icon2{
        top:18.5%;
        left: 10%;
        
      }
      .icon3{
        top:20%;
        right: 10%;
      }
      .iconInfo{
        cursor: pointer;
        z-index: 20;
        width: 2.5vw;
        min-height: 2px;
        aspect-ratio: 1/1;
        position: absolute;
    
        .tooltiptext{
          visibility: hidden;
          position: absolute;
          min-width: 500%;
          background-color: rgba(217, 217, 217, 0.1);
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 10%;
          z-index: 20;
          top: -150%;
          left: -210%;
          font-size: 0.8vw;
          font-family: Lexend;
          font-weight: 100;
        }
    
        .tooltiptext::after {
            content: " ";
            position: absolute;
            top: 110%;
            left: 45%;
            border-width: 0.6vw;
            border-style: solid;
            border-color: rgba(217, 217, 217, 0.1) transparent transparent transparent;
        }
    
    
    
      }
      .iconInfo:hover .tooltiptext {
        visibility: visible;
      }
      .icon3 .tooltiptext{
        width: 700%;
        left:-300%;
      }
    }


  }
  
  .musicContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom:15%;
  }

  .topContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    align-items: center;
  }

  .featureContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    align-items: center;
  }

  .recommendationContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    // .custom-selector {
    //   // Control style
    //   .control{
    //     background-color: rgba(58, 29, 78, 1);
    //     color: white;
    //     border: none;
    //     box-shadow: none;
    //     border-radius: 50%;
    //     font-family: Lexend;
    //     width: 100%;
    //     font-size: 1.5vw;
    //     &:hover {
    //       color: white;
    //       cursor: pointer;
    //     }
    //   };
    
    //   // SingleValue style
    //   .singleValue{
    //     color: white;
    //     width: 100%;
    //   };
    
    //   // DropdownIndicator style
    //   .dropdownIndicator{
    //     color: white;
    //     &:hover {
    //       color: white;
    //       cursor: pointer;
    //     }
    //   };
    
    //   // Option style
    //   .option{
    //     background-color: rgba(58, 29, 78, 1);
    //     color: white;
    //     width: 100%;
    //     &:hover {
    //       color: grey;
    //       cursor: pointer;
    //     }
    //   };
    
    //   // Placeholder style
    //   .placeholder{
    //     color: white;
    //     width: 100%;
    //   };
    
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   // width: 60%;
    // }
    .hiddenFeature{
      display: none;
    }
    
  }

#titleA {
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Lexend;
    font-weight: 700;
    font-size: 3vw;
    letter-spacing: 0px;
    position: relative;
}

#info{
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-between;
    color: rgba(255, 255, 255, 1);
    
}
.power {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 2vw;
  gap: 1.5em;
  margin-bottom: 2em;
  font-family: Sansation;
  img {
    position: relative;
    display: block;
    width: 12vw;
  }
  div, img {
    z-index: 300;
  }
}

.textBlock{
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50px;
    position: relative;
    padding: 2%;
}
#infoLeft{
  width: 50%;
  position: relative;
  justify-content: center;
  display: flex;

}
#infoRight{
  width: 50%;
  position: relative;

}

#textInfo{
    width: 50%;
    height: auto;
    padding: 5%;
    right: 5%;
    position: relative;
    font-size: 2vw;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
}

#graffic{
    top: 30%;
    align-items: center;
    bottom: 0px;
    right: 20%;
    width: 100%;
    display: flex;
    padding: 3%;
    flex-direction: column;
    justify-content: space-between;
    aspect-ratio: 1.8/1;
    position: relative;

}

#stat{
    width: 100%;
    position: relative;
}

#grafficA{
    width:35%;
    align-items: left;
    margin: 3%;
    margin-left: 5%;
    margin-right: 12%;
    position: relative;
    .image {
      width: 130%;
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 110%;
        height: 110%;
        border: 1vw solid black;
        border-radius: 50%;
        background-color: transparent;
        pointer-events: none;
        z-index: 0;
      }
    }
    
}

#sircle{
    width:100%
}

.song_content{
    position: relative;
    align-items: center;
    margin:3%;
    width:50%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10%;

}

.song_content > div {
  flex-basis: 23.33%;
}


.song_content.center{
    justify-content: center;
}

.song-card{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 20%;
    aspect-ratio: 1/2;  
    justify-content: flex-start;
}
.song-card_img {
    background-color: rgba(39, 31, 50, 1);
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    aspect-ratio: 1/1;  
  
    img{
      width:100%;
      aspect-ratio: 1/1;  
      &:hover {
        cursor: pointer;
    }
    }
  }

  .song-card_content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    padding: 0 2%;
    width: 100%;
}

.song-card_artist {
    margin-top: 2%;
    font-family: Urbanist;
    font-weight: 400;
    font-size: 2vw;
    overflow-wrap: break-word;
    margin-block-start: 0;
    margin-block-end: 0;

}
  
.rec_content{
    position: relative;
    align-items: center;
    width:100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap:2%;
}

#recomendation{
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    position: relative;;
    margin-bottom:10%;
    width: 100%;
}

.no_song{
    font-family: Sansation;
    font-weight: 700;
    font-size: 31.7px;
    justify-content: center;
}

.rec-card{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5%;
  width: calc(33.33% - 3%);
  aspect-ratio: 3/1;
  justify-content:space-between;
  position: relative;

}
.rec-card_img {
  background-color: rgba(217, 217, 217, 1);
  width: 25%;
  height: 75%;
  position: relative;
  object-fit: cover;
  img{
    width:100%;
    height: 100%;
  }
}

.rec-card_content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    // margin: 0px 10% 0px 10%;
}

.rec-card_text1 {
    text-align: center;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Lexend;
    font-weight: 300;
    font-size: 1.2vw;
    margin: 0px;
}
.rec-card_text2 {
  text-align: center;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(255, 255, 255, 0.676);
  font-style: normal;
  font-family: Lexend;
  font-weight: 300;
  font-size: 1vw;
  margin: 0px;
}

.rec-card_plus{
    width: 35px;
    height: 35px;
    position: relative;
    img:hover {
      cursor: pointer;
    }
}
.plus{
    width: 100%;
    position: relative;
    color: white;
}

#listing{
    width: 100%;
    display: flex;
    position:relative;
    justify-content: center;
    margin-bottom:5%;
}
.countries{
    background-color: rgba(58, 29, 78, 1);
    border-radius: 30px;
    width: 18%;
    display: flex;
    aspect-ratio: 18/5;
    position:relative;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    .react-select__control {
        background-color: rgba(58, 29, 78, 1);
        color: white;
        border: none;
        box-shadow: none;
      }
    
      .react-select__single-value {
        color: white;
      }
    
      .react-select__indicator-separator {
        display: none;
      }
    
      .react-select__dropdown-indicator {
        color: white;
        &:hover {
          color: white;
        }
      }
}


.selecting{
    text-align: left;
    white-space: pre-wrap;
    font-synthesis: none;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-family: Lexend;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    margin-right: 10%;
}
.options{
    width:10%;
    aspect-ratio: 2/1;
}

#flex-half{
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.featureInfo{
  background-color: rgba(255, 255, 255, 0.05);
  width: 80%;
  aspect-ratio: 1/0.8;
  border-radius: 75px;
  display: flex;
  padding: 2rem;
}

.topMenu{
  display: flex;
  direction: row;
  width: 100%;

  text-decoration: underline;
  gap: 7%;
  align-items: center;
  justify-content: center;
  h4 {
    color: rgba(255, 255, 255, 0.35); /* Set the initial color for all elements */
    text-decoration: underline;
    cursor: pointer; /* Change cursor to pointer when hovering over the elements */
    font-size: 1.5vw;
  }
  
  h4.active {
    color: white; /* Set the color for the active element */
  }
}

.smallHeight{
  max-height: 9rem;
}
map{
  width: 100%;
  height: 100%;
  area {
    width: 10rem;
    height: 2rem;
  }
}

.Web button{
  cursor: pointer;
  position: absolute;
  display: block;
  background-color: transparent;
  border-color: transparent;
  width: 22%;
  height: 5%;
  z-index: 30;
}
.circleMenu{
  display: none;
}

.css-b62m3t-container{
  width:90%;
  text-align: center;
}
.hiddenArrowMenu{
  display: none;
}

.loadingCircle {
  font-size: 40px;
  text-align: center;
  margin-left: 200px;
  position: absolute;
}
.featuresMobile{
  visibility: hidden;
  position: absolute;
}

@media (max-width: 700px) {
  .circleMenu{
    display: block;
    position:absolute;
    // background-color: rgba(8, 99, 99, 1);
    filter: blur(9rem);
    width: 139%;
    aspect-ratio: 517/563;
    top:-15%;
    left:-50%;
    border-radius: 100%;
    z-index:-1;

  }
  .hiddenArrowMenu{
    display: block;
    width: 15%;
    display: flex;
    align-items: center;
  }


  .modalIcon{
    visibility: hidden;
    height: 1000vh;
    z-index:8888;
    width: 1000vw;
    pointer-events: none; 
    background-color: rgba(72, 51, 87, 0.405);
    position: absolute;
    top:-100vh;
    left:-100vw;
  }
  #analytics{
    align-items: center;
    min-height: 600px;
    #analyze {
      width: 82%;
      background-color: rgba(97, 30, 150, 0.24);
      border-radius: 40px;
      aspect-ratio: 320/381;
      flex-direction: row;
      margin-top: 10%;
      padding-top: 10%;
      padding-bottom: 10%;
      margin-bottom: 20%;
      .iconInfo{
        width: 5vw;
        .tooltiptext{
          width: 60vw;
          left: -60vw;
          font-size: 3vw;
          z-index:9999;
        }
      }
      .iconInfo:active .tooltiptext,  .iconInfo:hover .modalIcon{
        visibility: visible;
      }
      .icon1,.icon2,.icon3{
        top: -3vh;
        right: -10%;
        left:auto;
        .tooltiptext{
          top:-8vh;
          border-radius: 15px;
        }
        .tooltiptext::after{
          content: " ";
          position: absolute;
          top: 120%;
          left: 95%;
          transform:rotate(-30deg);
          border-width: 2vw;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0.688) transparent transparent transparent;
        }
      }
      .icon1 .tooltiptext{
        background-color: rgb(5, 94, 106);
      }
      .icon2 .tooltiptext{
        background-color: rgb(109, 5, 73);
      }
      .icon3 .tooltiptext{
        background-color: rgb(0, 103, 48);
        top:-10vh;
      }
      #titleA{
        position: absolute;
        top:-25vw;
        font-size: 5vw;
        width:100%;
        font-family: Lexend Deca;

      }
      #info{
        height:auto;
        width: 100%;
        flex-direction: column;
        height: 100%;
      }
      .musicContainer{
        height: 100%;
        width:70%;
        margin:0;
        #info{
          position: relative;
          flex-direction: column-reverse;
          justify-content: space-between;
          align-items: center;

          .textBlock{
            background-color: transparent;
          }
          #infoLeft{
            width:100%;
            #textInfo{
              padding:0;
              width:100%;
              text-align: center;
              font-size: 4vw;
              right:0;
              font-family: Lexend Deca;
            }

          }
          #infoRight{
            width:100%;

            #graffic{
              padding:0;
              z-index: 3;
              aspect-ratio: 1/1;
              position: relative;
              right:0;
              top:0;
            }
          }
        }
        
      }

      .topContainer{
        width: 70%;
        #titleA{
          // top:-20%;
          width:140%;
        }

        .loadingCircle {
          font-size: 5vw;
          text-align: center;
          margin-left: 0;
          position: relative;
        }
        #info{
          position: relative;
          justify-content: space-between;
          align-items: center;

          #grafficA{
            width:100%;
            height: auto;
            aspect-ratio:2/1;
            margin: 0;
            margin-bottom: 60%;
            z-index: 30;
            display: flex;
            justify-content: center;
            .image {
              width:100%;
              height: 100%;
              &::before{
                width: 108%;
                height: 108%;
                border: 1.5vw solid black;
              }
            }
          }
          .no_song{
            font-size: 5vw;
          }
          .song_content{
            width: 100%;
            margin: 0;
            margin-top:-10%;
            margin-bottom: 5%;
            .song-card .song-card_artist{
                font-size:3.3vw;
            }
          }
      }
      .topMenu{
        position: absolute;
        top:50%;
        transform: translateY(-15%);

        z-index: 30;
        width: 100%;
        background-color: rgba(170, 27, 147, 0.54);
        font-size: 3vw;
        border-radius: 30px;
        padding: 2% 0;
        h4{
          font-size: 3vw;
          margin:0;
        }
      }
      }
      .featureContainer{
        width: 70%;

        #info{
          flex-direction: column-reverse;
        }
        #flex-half{
          width: 100%;

          .featureInfo{
            position: relative;
            background-color: transparent;
            width: 100%;
            height: auto;
            aspect-ratio: auto;
            display: flex;
            padding: 0;
            #swipeTitle{
              visibility: hidden;
              position: absolute;
            }
            .middleBody{
              height: auto;
              .textBody{
                gap:0;
                flex-direction: column;
                .texBody-text{
                  width:100%;
                  font-size:3vw;
                  p{
                    font-size:3vw;
                    text-align: center;
                  }
                }
                .texBody-example{
                  width:100%;
                  margin-top:10%;
                  height: 80px;
                  border-radius: 10px;
                  .teframe{
                    border-radius: 10px;
                  }
                  iframe {
                    display: block;
                    transition: opacity 0.125s;
                    .js & {
                      opacity: 0;
                      
                      &.loaded {
                        opacity: 1;
                      }
                    }
                  }
                }

              }
            }
            .arrowImg{
              visibility: hidden;
              position: absolute;
            }
          }
        }
      }
      .recommendationContainer {
        width: 96%;
        aspect-ratio: 36/40;
        padding: 0 2%;

        .hiddenFeature{
          display: block;
          width: 60%;
        }
        .countries{
          display: none;
        }
        #recomendation{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          color: rgb(255, 255, 255);
          text-align: center;
          position: relative;
          margin-bottom: 10%;
          width: 100%;
          max-height: 100%;
        }
        .rec_content {
          flex-direction: column;
          position: relative;
          gap: 4%;
          width: 90%;
          padding: 0 5%;
          height: 100%;
          overflow-y: scroll;
          justify-content: flex-start;
          z-index: 20;
          flex-wrap: nowrap;
          &::-webkit-scrollbar {
            width: 3px;
        }
            &::-webkit-scrollbar-thumb {
              background-color: white;
              border-radius: 5px;
            }
          
            &::-webkit-scrollbar-thumb:hover {
                background-color: white;
            }
          

            &::-webkit-scrollbar-thumb:active {
                background-color: white;
            }
          .rec-card {
            align-items: center;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0%;
            width: 90%;
            padding: 2% 5%;
            aspect-ratio: 272/42;
            justify-content: space-between;
            background-color: rgba(16, 15, 15, 0.53);
            position: relative;
            .rec-card_img{
              width: 10%;
              height: auto;
              min-height: 2px;
              aspect-ratio: 1/1;
            }
            .rec-card_text1 {
              font-size: 2.5vw;
            }
            .rec-card_text2 {
              font-size: 2vw;
            }
            .rec-card_plus {
              width: 7%;
              height: auto;
              min-height: 2px;
              aspect-ratio: 1/1;
          }
          }
        }
      }
    }
    .power {
      margin: 0em 0 2.5em 0;
    }

  } 
  .featuresMobile{
    visibility: visible;
    position: relative;
  }
  .hidden{
    // display: none;
    visibility: hidden;
    position: absolute;
    .featuresMobile{
      visibility: hidden;
      position: relative;
    }
  }
  .power {
    font-size: 3vw;
    margin: 2.5em 0;
    gap: 2.5em;
    img{
      width: 22vw;
    }
  }
}